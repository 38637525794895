import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { useAppDispatch, useAppSelector } from 'core/store';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import getSalesSowMeta from 'core/actions/Sales/GetSalesSowMeta/actions';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import ContentShrink from 'shared/ContentShrink';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import Loader from 'shared/Loader';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import WirelineEngagementsSowDataTable from '@EngagementsSowData/WirelineEngagementsSowDataTable';
import { columns, dynamicProductSchemaName } from '@EngagementsSowData/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { getColumnsFromMetaAndShchema } from 'core/utilities/getColumnsFromMetaAndShchema';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import { checkIfOrderNonAttTSD, isActiveNumbersItemCanceled } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

import './WirelineEngagementsSowData.scss';

const WirelineEngagementsSowDataContainer = () => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();

  const { isActionsUnavailable, isRequiredATTRoles, isProjectManager } = useUserPermissions();

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const metaColumns = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.meta);
  const isLoadingMeta = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.isLoading);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicProductSchemaName,
  ), [openApiSchemas],
  );

  const availableColumns = useMemo(
    () => getColumnsFromMetaAndShchema({ metaColumns, columns, retrieveSchema }),
    [metaColumns, retrieveSchema],
  );

  const enableRowSelection = (
    !isActionsUnavailable && ((row) => {
      const isCancaled = isActiveNumbersItemCanceled(row.original);
      const isRowNonAttTSD = checkIfOrderNonAttTSD(row.original);
      const isAttTSDForRoles = isRequiredATTRoles && isRowNonAttTSD;
      const isBillingSupportSellerRequestType = (
        row.sellerRequestType === sellerRequestTypeValues.billingSuport
      );

      return !isCancaled
        && !isAttTSDForRoles
        && !(isBillingSupportSellerRequestType && !isProjectManager);
    })
  );

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  useEffect(() => {
    (async () => {
      await dispatch(getSalesSowMeta());
    })();
  }, []);

  if (!customerId) {
    return null;
  }

  if (isLoadingMeta || isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <div className="sow-data">
      <BackToEditEngagementButton title="SOW Data" />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<string[]>
          id={tableIds.salesSow}
          columns={availableColumns}
          getDataAction={getSalesSowAction}
          enableEditing={!isActionsUnavailable}
          enableRowSelection={enableRowSelection}
          requestParams={[customerId]}
        >
          <WirelineEngagementsSowDataTable />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsSowDataContainer;

// eslint-disable-next-line no-unused-vars
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

interface IOrderRequestTypesNotMatchRolesProps {
  row: IActiveNumbersItem
  isProjectManager: boolean
  isBillingSpecialist: boolean
}

export const isOrderRequestTypesNotMatchRoles = ({
  row, isProjectManager, isBillingSpecialist,
}: IOrderRequestTypesNotMatchRolesProps) => {
  const isSellerTypeBSforPMRole = isProjectManager
&& row.sellerRequestType === sellerRequestTypeValues.billingSuport;

  const isSellerTypePMforBSRole = isBillingSpecialist
&& row.sellerRequestType === sellerRequestTypeValues.projectManagerSupport;

  return isSellerTypeBSforPMRole || isSellerTypePMforBSRole;
};

export const emptyOrderNumberCondition = (
  row: IActiveNumbersItem,
) => !row.orderNumber && row.sellerRequestType === sellerRequestTypeValues.contractSupport;
